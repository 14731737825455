import React from 'react'
import { Link } from "react-router-dom";
import styled from 'styled-components'
import * as Persistence from '../service/persistence'
import { ROUTE_WATCH, PARAM_PLAYLIST } from '../routes'
import { ThemeContext } from '../App'
import * as YouTubeApi from '../service/api/Youtube'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashCan as trash } from '@fortawesome/free-regular-svg-icons'
import { BigRect } from '../components/Ads'

import theme from '../theme/colors'
import empty from '../assets/images/empty.svg'


const Container = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ colors }) => colors.bgDark};
`

const ItemsWrapper = styled.div`
  max-width: 90vw;
  height: 100%;
  text-align: center;
  padding:10px;
  margin: 0 auto;
  margin-top: 10px;
`
const ItemContainer = styled.div`
  position: relative;
  display: inline-flex;
  max-width: 210px;
  margin: 0 12px 12px 0;
  overflow:hidden;
  cursor: pointer;

  &:hover {
    span {
      display: block;
    }
  }
`

const ItemLink = styled(Link)`
  display: flex;
  align-items: center;
  flex-direction: column;
`

const Title = styled.label`
  color: ${({ colors }) => colors.text};
  overflow: hidden;
  max-width: 200px;
  margin-top: 5px;
  padding: 0 2px;
  text-decoration: none;
  text-overflow: ellipsis;
  font-weight: bold;
  font-size: 14px;
`

const DeleteButton = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  padding: 0 4px;
  margin: 1px 1px 0 0;
  border-radius: 4px;
  color: #DDD;
  background-color: #000C;
  display: none;
`

const Description = styled.span`
  color: ${({ colors }) => colors.text};
  margin-top: 20px;
`
const NoItems = styled.span`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${({ colors }) => colors.text};
`

export default function List() {
  const themeContext = React.useContext(ThemeContext)

  const [playlists, setPlaylists] = React.useState([])

  React.useEffect(() => {
    const playlists = Persistence.getAllPlaylists()

    loadPlaylistInfo(playlists)
  }, [])

  const loadPlaylistInfo = async (playlists) => {

    const fetchedPlaylists = playlists.filter(plist => plist.title && plist.thumbnail)
    const playlistsWithMissingData = playlists.filter(plist => !plist.title || !plist.thumbnail)

    const idsWithMissingData = playlistsWithMissingData.map(plist => plist.playlist)

    if (!idsWithMissingData.length) {
      setPlaylists(fetchedPlaylists)
      return
    }

    const joinedIds = idsWithMissingData.join(',')
    console.log('joinedIds', joinedIds)

    const ytLists = await YouTubeApi.playlistInfo(joinedIds)
    console.log('ytLists', ytLists)

    const lists = ytLists.items?.map(ytList => ({
      id: ytList.id,
      playlist: ytList.id, // backward compatibility
      title: ytList.snippet.title,
      thumbnail: ytList.snippet.thumbnails.medium,
    }))

    Persistence.addPlayLists(lists)

    setPlaylists(fetchedPlaylists.concat(lists) || [])
  }

  const deletePlaylist = async ({ id, title }) => {
    const question = `Delete ${title}`
    if (window.confirm(question)) {
      await Persistence.removePlaylist(id)
      setPlaylists(playlists.filter(playlist => playlist.id !== id))
    }
  }

  const EmptyState = () => (
    <NoItems colors={colors}>
      <img src={empty} width="150" />
      There are no items
    </NoItems>
  )

  const Item = (props) => {
    const { playlist: { id, title, thumbnail }, colors } = props
    return <ItemContainer>
      <ItemLink title={title} to={`${ROUTE_WATCH}?${PARAM_PLAYLIST}=${id}`}>
        <img width={thumbnail.width} height={thumbnail.height} src={thumbnail.url} alt="" />

        <Title colors={colors}> {title}</Title>
      </ItemLink>
      <DeleteButton onClick={() => deletePlaylist({ id, title })} >
        <FontAwesomeIcon icon={trash} />
      </DeleteButton>
    </ItemContainer>
  }

  const colors = themeContext.isDark ? theme.dark : theme.light
  console.log(!playlists.length)
  return (
    <Container colors={colors}>
      <BigRect />
      <Description colors={colors} className="text-xl">
        Your progress on each list is saved locally
      </Description>
      <ItemsWrapper>
        {!playlists.length && <EmptyState />}

        {playlists.map((playlist, index) => <Item
          colors={colors}
          key={index}
          playlist={playlist}
        />)}
      </ItemsWrapper>
    </Container>
  )
}
