
export const save = (key, data) => {
    const dataString = JSON.stringify(data)
    localStorage.setItem(key, dataString)
}

export const get = (key, defaultValue) => {
    try { return JSON.parse(localStorage.getItem(key)) || defaultValue }
    catch (error) { return defaultValue }
}

export const allKeys = () => {
    return Object.keys(localStorage)
}

export const remove = (key) => {
    localStorage.removeItem(key)
}