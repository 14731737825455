import * as Storage from './storage'

const separator = '###'
const playlistPrefix = 'playlist' + separator
const KEY_IS_DARK = 'is_dark'

export const addPlayLists = (playlists) => {
    playlists.forEach(playlist => {
        const key = playlistPrefix + playlist.id
        Storage.save(key, { index: 0, start: 0, ...playlist })
    })
}

export const savePlaylistProgress = (data) => {
    const key = playlistPrefix + data.playlist
    const currentData = Storage.get(key, { index: 0, start: 0 })
    Storage.save(key, { ...currentData, ...data })
}

export const getPlaylistProgress = (playlist) => {
    const key = playlistPrefix + playlist
    return Storage.get(key, { index: 0, start: 0 })
}

export const getAllPlaylists = () => {
    return Storage.allKeys()
        .filter(key => key.startsWith(playlistPrefix))
        .map(key => Storage.get(key))
}

export const removePlaylist = (id) => {
    const key = playlistPrefix + id
    Storage.remove(key)
}

export const isDarkMode = () => {
    return Storage.get(KEY_IS_DARK, false)
}

export const saveIsDarkMode = (isDark) => {
    return Storage.save(KEY_IS_DARK, isDark)
}